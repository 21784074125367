import React, { useState } from 'react';

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';

const App = () => {
  const [currentStep, setCurrentStep] = useState('Step1');

  const showStep = (step) => {
    // console.log(`Navigating to ${step}`);
    setCurrentStep(step);
  };

  return (
    <div className="App">
      {currentStep === 'Step1' && <Step1 showStep={showStep} />}
      {currentStep === 'Step2' && <Step2 showStep={showStep} />}
      {currentStep === 'Step3' && <Step3 showStep={showStep} />}
      {currentStep === 'Step4' && <Step4 showStep={showStep} />}
      {currentStep === 'Step5' && <Step5 showStep={showStep} />}
      {/* Add more steps if necessary */}
    </div>
  );
};

export default App;
