import React from 'react';

const Step3 = ({ showStep }) => {
  return (
    <div id="step3" className="step relative bg-startup w-full h-screen bg-no-repeat bg-cover bg-center p-5 flex flex-col overflow-hidden">
      <div className="content-container mt-auto mb-2 z-20">
        <div id="root">
          <div className="Toastify"></div>
          <div style={{
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0px',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0px, 0px, 0px, 0px)',
            whiteSpace: 'nowrap',
            borderWidth: '0px',
            display: 'none'
          }}></div>
        </div>
        <div id="headlessui-portal-root">
          <div>
            <button type="button" aria-hidden="true" style={{
              position: 'absolute',
              width: '1px',
              height: '1px',
              padding: '0px',
              margin: '-1px',
              overflow: 'hidden',
              clip: 'rect(0px, 0px, 0px, 0px)',
              whiteSpace: 'nowrap',
              borderWidth: '0px'
            }}></button>
            <div>
              <div
                className="fixed left-0 top-0 right-0 z-50 h-screen w-screen bg-white/10 backdrop-blur-2xl transform translate-y-0 opacity-100"
                id="headlessui-dialog-:rj:" role="dialog" aria-modal="true"
                aria-describedby="headlessui-description-:rm:" aria-labelledby="headlessui-dialog-title-:rl:">
                <div className="absolute z-0 w-full h-full"></div>
                <div className="fixed inset-0 z-10 flex items-center justify-center">
                  <div className="absolute z-0 w-full h-full"></div>
                  <div className="relative w-full max-w-[360px] bg-gray15 p-6 pt-2.5 rounded-3xl mx-5"
                    id="headlessui-dialog-panel-:rk:">
                    <div className="all-center -mt-12 mb-4 text-2xl">
                      <div className="relative inline-flex self-center mx-auto z-10">
                        <div
                          className="modal-icon-outer absolute inset-0 rounded-full opacity-40 bg-gradient-green-icon">
                        </div>
                        <div
                          className="modal-icon-inner m-2.5 all-center w-18 h-18 rounded-full text-5xl shadow-xl bg-gradient-green-icon drop-shadow-success">
                          <span className="icon-app_checkmark"></span>
                        </div>
                      </div>
                    </div>
                    <h2 className="text-title-2 text-yellow text-center my-2" id="headlessui-dialog-title-:rl:">Success!</h2>
                    <p className="text-white2 opacity-70 text-center text-body-3 mt-4 mb-6"
                      id="headlessui-description-:rm:">Your password has been set</p>
                    <button
                      className="all-center outline-none border-none text-white py-3 px-10 rounded-3xl transition-all duration-300 hover:brightness-90 hover:drop-shadow-sm active:brightness-80 bg-green hover:!bg-green w-full uppercase text-body-3"
                      tabIndex="0" onClick={() => showStep('Step4')}>Ok</button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" aria-hidden="true" style={{
              position: 'absolute',
              width: '1px',
              height: '1px',
              padding: '0px',
              margin: '-1px',
              overflow: 'hidden',
              clip: 'rect(0px, 0px, 0px, 0px)',
              whiteSpace: 'nowrap',
              borderWidth: '0px'
            }}></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
