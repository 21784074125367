import React, { useEffect } from 'react';

const Step2 = ({ showStep }) => {
  useEffect(() => {
    const passwordInput = document.getElementById("password");
    const confirmPasswordInput = document.getElementById("confirmPassword");
    const setupButton = document.getElementById("setupButton");
    const passwordMatchMessage = document.getElementById("passwordMatchMessage");

    function validatePassword() {
      const password = passwordInput.value;
      const confirmPassword = confirmPasswordInput.value;
      const lengthValid = password.length >= 8;
      document.getElementById("length").classList.toggle("valid", lengthValid);
      document.getElementById("length").classList.toggle("invalid", !lengthValid);
      document.getElementById("length").classList.toggle("default", !lengthValid && password.length === 0);
      const complexityValid =
        /[A-Za-z]/.test(password) &&
        /[0-9]/.test(password) &&
        /[^A-Za-z0-9]/.test(password);
      document.getElementById("complexity").classList.toggle("valid", complexityValid);
      document.getElementById("complexity").classList.toggle("invalid", !complexityValid);
      document.getElementById("complexity").classList.toggle("default", !complexityValid && password.length === 0);
      const matchValid = password === confirmPassword;
      passwordMatchMessage.style.display = matchValid || confirmPassword.length === 0 ? 'none' : 'block';
      const isValid = lengthValid && complexityValid && matchValid;
      setupButton.disabled = !isValid;
      setupButton.classList.toggle("enabled-button", isValid);
    }

    passwordInput.addEventListener("input", validatePassword);
    confirmPasswordInput.addEventListener("input", validatePassword);
    document.getElementById("passwordForm").addEventListener("submit", function (event) {
      event.preventDefault();
      if (!setupButton.disabled) {
        showStep('Step3');
      }
    });

    return () => {
      passwordInput.removeEventListener("input", validatePassword);
      confirmPasswordInput.removeEventListener("input", validatePassword);
    };
  }, [showStep]);

  const togglePassword = (id) => {
    const input = document.getElementById(id);
    if (input.type === "password") {
      input.type = "text";
    } else {
      input.type = "password";
    }
  };

  return (
    <div id="step2" className="step relative bg-startup w-full h-screen bg-no-repeat bg-cover bg-center p-5 flex flex-col overflow-hidden">
      <div className="main-layout-header z-50 flex justify-between items-center relative py-7 px-3 text-lg h-header">
        <div className="main-layout-left-action left-0 h-full flex items-center justify-center">
          <div
            className="transition rounded-full hover:text-yellow hover:bg-gray25 cursor-pointer all-center ml-4 text-xl"
            style={{ width: '32px', height: '32px' }} onClick={() => showStep('Step1')}>
            <span className="icon-app_arrow_left"></span>
          </div>
        </div>
        <div className="flex-1 text-white text-title-3 pl-2">Setup Password</div>
        <div className="main-layout-right-action right-0 h-full flex items-center justify-center"></div>
      </div>
      <div className="main-layout-content bg-gray1 rounded-3xl flex-1 text-white text-sm flex flex-col overflow-auto p-5">
        <form id="passwordForm" className="flex flex-1 flex-col gap-4">
          <div className="w-full block">
            <label htmlFor="password" className="pl-4 mb-2 block text-white text-body-3">Your Password</label>
            <div
              className="bg-gray05 transition-all text-white outline-none border-none rounded-3xl text-sm w-full flex items-center gap-2 py-3 px-4">
              <input id="password" placeholder="Input your password string"
                className="w-full outline-none bg-transparent flex-1 placeholder:text-gray4 pl-2 placeholder:text-body-3"
                type="password" name="password" />
              <div className="cursor-pointer all-center transition-all hover:text-yellow active:brightness-90 text-xl"
                onClick={() => togglePassword('password')}>
                <span className="icon-app_show"></span>
              </div>
            </div>
          </div>
          <ul className="text-sm default">
            <li id="length" className="transition flex items-center text-body-3">
              <span className="icon-app_close mr-2"></span>At least 8 characters
            </li>
            <li id="complexity" className="transition flex items-center mt-1 text-body-3">
              <span className="icon-app_close mr-2"></span>At least 1 character, 1 digit, 1 symbol
            </li>
          </ul>
          <div className="w-full block">
            <label htmlFor="confirmPassword" className="pl-4 mb-2 block text-white text-body-3">Confirm Password</label>
            <div
              className="bg-gray05 transition-all text-white outline-none border-none rounded-3xl text-sm w-full flex items-center gap-2 py-3 px-4">
              <input id="confirmPassword" placeholder="Password doesn't match"
                className="w-full outline-none bg-transparent flex-1 placeholder:text-gray4 pl-2 placeholder:text-body-3"
                type="password" name="confirmPassword" />
              <div className="cursor-pointer all-center transition-all hover:text-yellow active:brightness-90 text-xl"
                onClick={() => togglePassword('confirmPassword')}>
                <span className="icon-app_show"></span>
              </div>
            </div>
            <div id="passwordMatchMessage" className="invalid" style={{ display: 'none' }}>Password doesn't match</div>
          </div>
          <button id="setupButton" type="submit" disabled
            className="all-center outline-none border-none text-white py-3 px-10 rounded-3xl transition-all duration-300 hover:brightness-90 hover:drop-shadow-sm active:brightness-80 w-full bg-gray3 hover:!bg-gray3 mt-auto text-body-3 cursor-pointer">
            Setup Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default Step2;
