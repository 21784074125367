import React from 'react';

const Step1 = ({ showStep }) => {
  return (
    <div id="step1" className="step active relative bg-startup w-full h-screen bg-no-repeat bg-cover bg-center p-5 flex flex-col overflow-hidden">
      <div className="effect-container flex-1 relative">
        <img className="moving-up-down absolute" style={{ top: '-7rem', left: '25%' }} src="assets/media/startup_1.svg" alt="" />
        <img className="moving-up-down mt-10 delay-[1s] duration-[4s]" src="assets/media/startup_2.svg" alt="" />
        <img className="moving-up-down relative" style={{ left: '50%' }} src="assets/media/startup_3.svg" alt="" />
      </div>
      <div className="content-container mt-auto mb-home z-20">
        <div className="title-container text-center mb-12">
          <h2 className="sub-title text-title-2 mb-1 font-thin">Welcome to</h2>
          <div className="title text-display-2 font-light">Coin98 Wallet</div>
        </div>
        <div className="button-action flex flex-col gap-3">
          <button
            className="all-center outline-none border-none text-white py-3 px-10 rounded-3xl transition-all duration-300 hover:brightness-90 hover:drop-shadow-sm active:brightness-80 bg-yellow hover:!bg-yellow w-full text-body-3"
            onClick={() => showStep('Step2')}>
            Get Started
          </button>
        </div>
      </div>
      <div id="tsparticles">
        <canvas data-generated="false" style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          zIndex: 1,
          top: 0,
          left: 0,
          pointerEvents: 'none'
        }} aria-hidden="true" width="4480" height="2198"></canvas>
      </div>
    </div>
  );
};

export default Step1;
